<template lang="pug">
  tr.matchings-item(:class="{ added: isAdded }")
    td.sticky-col.sync
      div.toggle-container(
        v-b-tooltip.hover="{ title: $t('shop_matching.cannot_delete_or_change'), placement: 'top', disabled: !(hasMasterShop && item.sync) }"
      )
        AppToggle(
          :value="item.sync"
          :disabled="!hasEditPermission() || (!item.sync && isActiveShopsCountLimitExceed) || (hasMasterShop && item.sync)"
          :title="item.sync ? $t('shop_matching.sync_toggle_tooltip') : ''"
          v-b-tooltip.hover
          @change="setItemSync"
        )
    td.sticky-col.editable.item-name
      input.editable-field.form-control(
        :class="{ invalid: isInvalid }"
        type="text"
        :value="itemName"
        :disabled="!hasEditPermission()"
        @input="setItemName"
      )
    td.dp(v-if="currentOrganization.dp")
      AppCheckbox.highlight-checked(
        :value="item.dp"
        :disabled="!!item.id"
        @change="setItemDp"
      )
    td.item-name-dropdown(
      :id="`inventory-groups-cell-${item.id}`"
      v-if="isSharedInventoryEnabled"
    )
      AppDropdown.inventory-group-name(
        v-if="!item.inventory_group_id"
        :id="`inventory-groups-${item.id}`"
        size="small"
        searchable
        allow-empty
        close-on-select
        :placeholder="$t('shop_matching.select_inventory_group')"
        :value="item.inventory_group"
        :items="filteredInventoryGroups(item)"
        :loading="inventoryGroupsLoading"
        :class="{ inactive: !item.sync }"
        :tips="''"
        :creatable="false"
        :editable="false"
        v-slot="{ opened }"
        order-direction="keep"
        @select="setInventoryGroup"
        @remove="setInventoryGroup({})"
        @create-item="saveInventoryGroup"
        @update-item="handleUpdateInventoryGroup"
        @delete-item="deleteInventoryGroup"
      )
      .immutable.inventory-group-name(
        v-else
        :class="{ inactive: !item.sync }"
        :title="item.inventory_group.name"
      )
        span {{ item.inventory_group.name }}

    td.sources-item(
      v-for="ota in otaList"
      :key="ota.id"
      :class="ota.rawName"
    )
      .immutable(
        v-if="isSourceItemSelectionDisabled(ota.id)"
        :class="{ inactive: !currentSourcesItemsByOtaId[ota.id].active || !item.sync }"
        :title="currentSourcesItemsByOtaId[ota.id].name"
      )
        span.m-r-3(v-if="!currentSourcesItemsByOtaId[ota.id].active")
          | ({{ $t("shop_matching.inactive") }})
        span {{ currentSourcesItemsByOtaId[ota.id].name }}
      AppDropdown(
        v-else
        lazy-scroll
        :lazy="false"
        size="small"
        searchable
        allow-empty
        close-on-select
        :placeholder="$t('shop_matching.select_shop')"
        :items="dropDownItems(ota.id, item.dp)"
        :value="currentSourcesItemsByOtaId[ota.id]"
        :is-item-disabled="denySelectSourcesItem"
        :class="{ inactive: !item.sync }"
        @select="setSourcesItem"
        @remove="removeSourcesItem"
      )
        template(
          v-if="sourcesItemsLoading"
          v-slot:selectIcon
        )
          FaIcon.app-select-icon(
            icon="spinner"
            spin
          )
    td.sticky-col.actions.d-inline-flex
      .action-info
        AppIconButton.info-icon(
          useAppIcon
          icon="exclamationCircle"
          v-if="item.id"
          @click="openModal()"
        )
      div(
        v-b-tooltip.hover="{ title: $t('shop_matching.cannot_delete_or_change'), placement: 'right', disabled: !hasMasterShop }"
      )
        AppIconButton.action-delete(
          useAppIcon
          v-if="hasEditPermission()"
          icon="trash"
          @click="deleteItemHandle"
          :disabled="hasMasterShop"
        )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"
  import withMatchingPageMethods from "@/mixins/matching_page/withMatchingItemMethods"
  import withModal from "@/mixins/withModal"

  // misc
  import { find, isEmpty } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"
  import { mapGetters } from "vuex"

  export default {
    props: {
      inventoryGroupsList: {
        type: Array,
        default: () => new Array()
      },
      inventoryGroupsLoading: {
        type: Boolean,
        default: true
      },
      createInventoryGroup: {
        type: Function,
        require: true
      },
      updateInventoryGroup: {
        type: Function,
        require: true
      },
      deleteInventoryGroup: {
        type: Function,
        require: true
      },
      isActiveShopsCountLimitExceed: {
        type: Boolean,
        default: false
      },
      otaList: {
        type: Array,
        default: () => new Array()
      }
    },

    mixins: [withMatchingPageMethods("sources_shops"), withPermissions, withModal],

    components: {
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    computed: {
      ...mapGetters(["currentOrganization"]),

      // when organization has shared inventory mode it is needed to
      // hide ability to create/update/delete inventory group from dropdown
      isSharedInventoryEnabled,

      hasMasterShop() {
        return Boolean(find(this.item.sources_shops, { master: true }))
      }
    },

    methods: {
      isEmpty,

      filteredInventoryGroups(item) {
        return this.inventoryGroupsList.filter(({ dp }) => dp === item.dp)
      },

      setInventoryGroup(inventory_group) {
        this.updateItemPartial({ inventory_group })
      },

      saveInventoryGroup(name) {
        this.createInventoryGroup({ name }).then(groups => {
          const newGroup = find(groups, { name })
          if (newGroup !== undefined) {
            this.setInventoryGroup(newGroup)
          }
        })
      },

      handleUpdateInventoryGroup({ item: { id }, updated }) {
        this.updateInventoryGroup({ id, name: updated })
      },

      openModal() {
        this.$openModal({
          title: this.$t("header.title.sync_list"),
          component: () => import("@/components/SyncList/ShopMatchingModal"),
          size: "large",
          props: {
            shop: this.item
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/matchings.sass"

  .matchings-item
    td
      +matchings-item-td

    +matchings-item-edit

  .toggle-container
    width: 33px
</style>
